<template>
  <div class="flex flex-col">
    <div class="-my-2 overflow-x-auto md:overflow-hidden sm:-mx-6 lg:-mx-8">
      <div
        class="inline-block min-w-full py-2 overflow-hidden align-middle sm:px-6 lg:px-8"
      >
        <div
          class="flex flex-col items-center justify-center h-48"
          v-if="audios.length === 0"
        >
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              class="w-12 h-12 text-base-300"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
          </div>
          <div class="my-2 font-normal text-gray-700">No hay audios</div>
        </div>
        <div
          v-else
          class="overflow-hidden shadow baudio-b baudio-gray-200 sm:rounded-lg"
        >
          <table
            class="min-w-full overflow-hidden divide-y divide-gray-200 rounded-lg"
          >
            <thead
              class="hidden md:table-header-group print:table-header-group"
            >
              <tr>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase print:hidden md:table-cell bg-gray-50"
                >
                  Datos
                </th>
                <th
                  class="hidden px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase print:hidden md:table-cell bg-gray-50"
                >
                  Estado
                </th>
                <th
                  class="hidden px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase print:hidden md:table-cell bg-gray-50"
                >
                  Mensaje
                </th>
                <th
                  class="py-3 text-xs font-medium leading-4 tracking-wider text-right text-gray-500 uppercase print:hidden sm:hidden bg-gray-50"
                >
                  Procesado
                </th>
              </tr>
            </thead>
            <tbody
              class="flex-1 bg-white divide-y divide-gray-300 sm:flex-none print:flex-none"
            >
              <tr
                class="flex flex-col mb-2 flex-no wrap sm:table-row print:table-row sm:mb-0"
                v-for="(audio, indexAudio) in audios"
                :key="audio._id"
              >
                <td
                  class="pl-6 text-xs py-3 overflow-hidden max-w-xxs md:table-cell"
                >
                  <div class="">
                    De
                    <strong class="font-medium">{{ audio.comprador }}</strong>
                  </div>
                  <div class="">
                    Para
                    <strong class="font-medium">{{
                      audio.dedicatoria.destinatario
                    }}</strong>
                  </div>
                  <div class="text-sm md:text-xs">
                    <strong class="font-semibold">{{
                      audio.dedicatoria.mensaje
                    }}</strong>
                  </div>

                  <!-- Badge para SKUS -->
                  <div class="flex flex-wrap">
                    <span
                      v-for="(sku, indexSku) in audio.skus"
                      :key="indexSku"
                      class="inline-flex items-center px-2.5 py-0.5 mt-1 mr-1 text-xs font-medium leading-4 rounded bg-green-200 text-green-900"
                    >
                      {{ sku }}
                    </span>
                  </div>
                </td>
                <td
                  class="px-6 md:py-4 text-sm whitespace-no-wrap md:table-cell"
                  style="vertical-align: top"
                >
                  <!-- Envio manual (enviadoManual:true,false) -->
                  <div v-if="audio.enviadoManualTest">
                    <span
                      class="inline-flex px-2 text-xs font-medium leading-5 text-gray-100 bg-gray-400 rounded-full"
                    >
                      Enviado Manual
                    </span>
                    <!-- Fecha de cuando humanize -->
                    <div class="text-xs italic text-gray-500">
                      hace
                      {{ audio.sentAt | moment("from", true) }}
                    </div>
                  </div>
                  <!-- Envio automatico (enviadoManual:true,false) -->
                  <span
                    v-else
                    class="inline-flex px-2 text-xs font-medium leading-5 text-orange-100 bg-orange-400 rounded-full"
                  >
                    Pendiente
                  </span>

                  <!-- Icono de estado pendiente/procesado/enviado -->
                  <!-- <span
                    v-if="!audio.estado"
                    class="inline-flex px-2 text-xs font-medium leading-5 text-orange-100 bg-orange-400 rounded-full"
                  >
                    Pendiente
                  </span>
                  <span
                    v-else-if="audio.estado === 'procesado'"
                    class="inline-flex px-2 text-xs font-medium leading-5 text-green-100 bg-green-500 rounded-full"
                  >
                    Procesado
                  </span>
                  <span
                    v-else-if="audio.estado === 'enviado'"
                    class="inline-flex px-2 text-xs font-medium leading-5 text-blue-100 bg-blue-500 rounded-full"
                  >
                    Enviado
                  </span>
                  <div v-if="audio.sentAt" class="text-xs italic text-gray-500">
                    hace
                    {{ audio.sentAt }}
                  </div> -->
                </td>
                <!-- <td class="hidden px-6 py-4 whitespace-no-wrap md:table-cell">
                  <span
                    v-if="audio.archive"
                    class="inline-flex px-2 text-xs font-medium leading-5 text-green-100 bg-green-500 rounded-full"
                  >
                    Archivada
                  </span>
                  <span
                    v-else
                    class="inline-flex px-2 text-xs font-medium leading-5 text-orange-100 bg-orange-400 rounded-full"
                  >
                    Pendiente
                  </span>
                  <div class="text-xs italic text-gray-500">
                    hace
                    {{
                      audio.foodUpdated ||
                      audio.createdAt | moment("from", true)
                    }}
                  </div>
                </td> -->
                <td
                  style="vertical-align: top"
                  class="px-6 md:py-4 text-sm font-medium leading-5 text-right whitespace-no-wrap md:w-32"
                >
                  <div class="relative">
                    <!-- Icono de whatsapp -->
                    <a
                      :key="indexAudio"
                      v-if="audio.telefono"
                      :href="generarLinkWhatsapp(audio)"
                      target="_blank"
                      @click="marcarEnviado(indexAudio)"
                      v-tooltip="{
                        content:
                          'Enviar por WhatsApp al ' + obtenerTelefono(audio),
                        placement: 'top',
                        trigger: 'hover',
                      }"
                      class="inline-flex items-center text-green-500 px-2 py-2 text-sm font-medium leading-3"
                    >
                      <svg
                        class="w-5 h-5 cursor-pointer"
                        fill="currentColor"
                        role="img"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <title>WhatsApp icon</title>
                        <path
                          d="M17.472 14.382c-.297-.149-1.758-.867-2.03-.967-.273-.099-.471-.148-.67.15-.197.297-.767.966-.94 1.164-.173.199-.347.223-.644.075-.297-.15-1.255-.463-2.39-1.475-.883-.788-1.48-1.761-1.653-2.059-.173-.297-.018-.458.13-.606.134-.133.298-.347.446-.52.149-.174.198-.298.298-.497.099-.198.05-.371-.025-.52-.075-.149-.669-1.612-.916-2.207-.242-.579-.487-.5-.669-.51-.173-.008-.371-.01-.57-.01-.198 0-.52.074-.792.372-.272.297-1.04 1.016-1.04 2.479 0 1.462 1.065 2.875 1.213 3.074.149.198 2.096 3.2 5.077 4.487.709.306 1.262.489 1.694.625.712.227 1.36.195 1.871.118.571-.085 1.758-.719 2.006-1.413.248-.694.248-1.289.173-1.413-.074-.124-.272-.198-.57-.347m-5.421 7.403h-.004a9.87 9.87 0 01-5.031-1.378l-.361-.214-3.741.982.998-3.648-.235-.374a9.86 9.86 0 01-1.51-5.26c.001-5.45 4.436-9.884 9.888-9.884 2.64 0 5.122 1.03 6.988 2.898a9.825 9.825 0 012.893 6.994c-.003 5.45-4.437 9.884-9.885 9.884m8.413-18.297A11.815 11.815 0 0012.05 0C5.495 0 .16 5.335.157 11.892c0 2.096.547 4.142 1.588 5.945L.057 24l6.305-1.654a11.882 11.882 0 005.683 1.448h.005c6.554 0 11.89-5.335 11.893-11.893a11.821 11.821 0 00-3.48-8.413Z"
                        />
                      </svg>
                    </a>

                    <!-- Icono para grabar -->
                    <!-- <button
                      v-if="!audio.estado"
                      @click="abrirModal(indexAudio)"
                      class="inline-flex items-center px-2 py-2 text-sm font-medium leading-3 text-orange-100 transition-colors duration-150 bg-orange-500 border border-transparent rounded-lg active:bg-orange-600 hover:bg-orange-600 focus:outline-none focus:shadow-outline-orange"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="w-4 h-4"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M12 18.75a6 6 0 006-6v-1.5m-6 7.5a6 6 0 01-6-6v-1.5m6 7.5v3.75m-3.75 0h7.5M12 15.75a3 3 0 01-3-3V4.5a3 3 0 116 0v8.25a3 3 0 01-3 3z"
                        />
                      </svg>
                      Grabar
                    </button>
                    <button
                      v-else
                      @click="$emit('play', audio)"
                      class="inline-flex items-center px-2 py-2 text-sm font-medium leading-3 text-blue-100 transition-colors duration-150 bg-blue-500 border border-transparent rounded-lg active:bg-blue-600 hover:bg-blue-600 focus:outline-none focus:shadow-outline-blue"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="w-4 h-4 mr-1"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                        />
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M15.91 11.672a.375.375 0 010 .656l-5.603 3.113a.375.375 0 01-.557-.328V8.887c0-.286.307-.466.557-.327l5.603 3.112z"
                        />
                      </svg>

                      Escuchar
                    </button> -->
                  </div>
                </td>
              </tr>

              <!-- More rows... -->
            </tbody>
          </table>
          <div
            v-if="totalResults > limit"
            @click="nextPage()"
            class="items-center p-3 text-sm font-medium text-center text-indigo-300 cursor-pointer glex hover:text-indigo-500"
          >
            Cargar 20 más de {{ totalResults - limit }} resultados.
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: {
    initItems: {
      type: Array,
      default: () => [],
    },
    hide: {
      type: Array,
      default: () => [],
    },
    totalResults: {
      type: Number,
      default: 0,
    },
    eventPrice: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      showImage: null,
      upload: null,
      limit: 20,
      busy: false,
      items: [],
    };
  },
  created() {
    this.items = this.initItems;
  },
  watch: {
    initItems() {
      this.items = this.initItems;
    },
  },
  methods: {
    ...mapActions("regalo-audio", ["patch"]),
    obtenerTelefono(audio) {
      if (audio.dedicatoria.telefono) {
        // e164
        return audio.dedicatoria.telefono.e164;
      }

      if (audio.telefono) {
        return audio.telefono.replace(/\s/g, "");
      }
      return "";
    },
    marcarEnviado(indexAudio) {
      const audio = this.items[indexAudio];
      // Siguiente audio
      this.patch([
        audio._id,
        {
          enviadoManualTest: true,
          sentAt: new Date(),
        },
      ]);

      // Actualizar en el listado
      this.$set(this.items[indexAudio], "enviadoManualTest", true);
      this.$set(this.items[indexAudio], "sendAt", new Date());
    },
    abrirModal(indexAudio) {
      const audio = this.items[indexAudio];
      // Siguiente audio
      this.$emit("record", { audio: audio, index: indexAudio });
    },
    generarLinkWhatsapp(audio) {
      const texto = "";
      //Quitar + y espacios
      const phone = audio.telefono.replace(/\s/g, "").replace("+", "");

      return "https://api.whatsapp.com/send?phone=" + phone + "&text=" + texto;
    },
    remove(id) {
      this.$emit("remove", id);
    },
    nextPage() {
      this.limit += 20;
      this.$emit("next-page", this.limit);
    },
  },
  computed: {
    audios() {
      return this.items.filter((audio) => audio.dedicatoria);
    },
  },
};
</script>

<style lang="css" scoped>
@media (min-width: 640px) {
  table {
    display: inline-table !important;
  }

  thead tr:not(:first-child) {
    display: none;
  }
}

td:not(:last-child) {
  baudio-bottom: 0;
}

th:not(:last-child) {
  baudio-bottom: 2px solid rgba(0, 0, 0, 0.1);
}
.max-w-xxs {
  max-width: 12rem;
}
/* For cellphone */
@media (max-width: 640px) {
  .max-w-xxs {
    max-width: 100%;
  }
}
</style>
