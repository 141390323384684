<template>
  <div
    class="flex flex-col items-center justify-center p-3 my-3 border rounded"
  >
    <button @click="toggleRecording">
      <i class="material-icons" style="font-size: 30px">{{
        isPlaying ? "stop" : "mic"
      }}</i>
    </button>

    <span>{{ recordingTime }} segundos</span>
  </div>
</template>
  
  <script>
export default {
  data() {
    return {
      mediaStream: null,
      mediaRecorder: null,
      audioChunks: [],
      recording: false,
      recordingTime: 0,
      isPlaying: false,
      timer: null,
      extension: null,
    };
  },
  created() {
    // true on chrome, false on firefox
    console.log(
      "audio/webm:" + MediaRecorder.isTypeSupported("audio/webm;codecs=opus")
    );
    // false on chrome, true on firefox
    console.log(
      "audio/ogg:" + MediaRecorder.isTypeSupported("audio/ogg;codecs=opus")
    );

    if (MediaRecorder.isTypeSupported("audio/webm;codecs=opus")) {
      this.extension = "webm";
    } else {
      this.extension = "ogg";
    }
  },
  methods: {
    async toggleRecording() {
      if (!this.recording) {
        this.recordingTime = 0;

        try {
          this.mediaStream = await navigator.mediaDevices.getUserMedia({
            audio: true,
          });
          this.mediaRecorder = new MediaRecorder(this.mediaStream, {
            audioBitsPerSecond: 256000,
            videoBitsPerSecond: 2500000,
            bitsPerSecond: 2628000,
            mimeType: "audio/" + this.extension + ";codecs=opus",
          });

          this.mediaRecorder.ondataavailable = (event) => {
            if (event.data.size > 0) {
              this.audioChunks.push(event.data);
            }
          };

          this.mediaRecorder.onstop = () => {
            const audioBlob = new Blob(this.audioChunks, {
              type: "audio/" + this.extension,
              bitsPerSecond: 128000,
            });
            const audioUrl = URL.createObjectURL(audioBlob);
            this.$emit("finished", { url: audioUrl, blob: audioBlob });
            this.audioChunks = [];
            this.recording = false;
            this.isPlaying = false;
            clearInterval(this.timer);
          };

          this.mediaRecorder.start();
          this.recording = true;
          this.isPlaying = true;
          this.startRecordingTimer();
        } catch (error) {
          console.error("Error al iniciar la grabación: ", error);
        }
      } else {
        this.stopRecording();
        // if (this.isPlaying) {
        //   this.mediaRecorder.pause();
        //   this.isPlaying = false;
        //   clearInterval(this.timer);
        // } else {
        //   this.mediaRecorder.resume();
        //   this.isPlaying = true;
        //   this.startRecordingTimer();
        // }
      }
    },
    stopRecording() {
      if (this.mediaRecorder && (this.recording || this.isPlaying)) {
        this.mediaRecorder.stop();
        this.mediaStream.getTracks().forEach((track) => track.stop());
        this.clearRecordingTimer();
      }
    },
    startRecordingTimer() {
      this.timer = setInterval(() => {
        this.recordingTime++;
      }, 1000);
    },
    clearRecordingTimer() {
      clearInterval(this.timer);
    },
  },
};
</script>
  
  <style scoped>
/* Estilos opcionales */
</style>
  