<template>
  <!-- This example requires Tailwind CSS v2.0+ -->
  <div
    class="fixed z-10 inset-0 overflow-y-auto"
    aria-labelledby="modal-title"
    role="dialog"
    aria-modal="true"
  >
  
    <div
      class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
    >
      <!--
        Background overlay, show/hide based on modal state.

        Entering: "ease-out duration-300"
          From: "opacity-0"
          To: "opacity-100"
        Leaving: "ease-in duration-200"
          From: "opacity-100"
          To: "opacity-0"
      -->
      <div
        class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
        aria-hidden="true"
      ></div>

      <!-- This element is to trick the browser into centering the modal contents. -->
      <span
        class="hidden sm:inline-block sm:align-middle sm:h-screen"
        aria-hidden="true"
        >&#8203;</span
      >

      <!--
        Modal panel, show/hide based on modal state.

        Entering: "ease-out duration-300"
          From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          To: "opacity-100 translate-y-0 sm:scale-100"
        Leaving: "ease-in duration-200"
          From: "opacity-100 translate-y-0 sm:scale-100"
          To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
      -->
      <div
        class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
      >
      
        <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
          <div class="sm:flex sm:items-start">
            <!-- <div class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
              <svg class="h-6 w-6 text-red-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
              </svg>
            </div> -->
            <div
              class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left flex-grow"
            >
              <h3
                class="text-lg leading-6 font-medium text-gray-900"
                id="modal-title"
              >
                {{ value.estado !== "procesado" ? "Grabar" : "Escuchar" }} audio
              </h3>

              <span
                v-if="value.audio && value.audio.nombre"
                class="text-sm text-gray-500"
              >
                para {{ value.audio.nombre }} {{ value.audio.apellido }}
              </span>
              <div class="mt-2 w-full">
                <w-loading
                  v-if="loader"
                  :label="loader === 'processing' ? 'Guardando datos' : null"
                  class="w-full"
                />
                <div v-else>
                  <div>Comprador: {{ value.audio.comprador }}</div>
                  <div class="mb-2">Destinatario: {{ value.audio.destinatario }}</div>

                  <!-- Show text with interlineado -->
                  <div
                    v-if="value.audio.dedicatoria"
                    class="text-sm text-dark border rounded p-2 mb-2 text-lg border-gray-300 leading-relaxed"
                  >
                    {{ value.audio.dedicatoria.mensaje }}
                  </div>

                  <w-grabar @finished="callback" />
                  <br />
                  <div class="">
                    <!-- Two columns one with audio player and tiny right one with a delete icon -->
                    <div v-if="url" class="flex flex-row items-center">
                      <div class="flex-grow">
                        <audio
                          class="record-preview"
                          :src="url"
                          controls
                          :title="file_name"
                        />
                      </div>
                      <div
                        v-if="value.estado !== 'procesado'"
                        class="flex-shrink-0 pl-2"
                      >
                        <button
                          @click="deleteAudio()"
                          class="text-red-500 hover:text-red-700"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            class="w-6 h-6"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                            />
                          </svg>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
          <button
            v-if="value.estado !== 'procesado'"
            @click="save()"
            :disabled="!isValid"
            :class="{
              'bg-gray-500': !isValid,
              'bg-indigo-600': isValid,
            }"
            type="button"
            class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
          >
            <span v-if="!busy">Guardar audio y continuar</span>
            <span v-else>Guardando...</span>
          </button>
          <button
            @click="hideModal()"
            type="button"
            class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
          >
            Cerrar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
// import { mapActions } from "vuex";
// import AudioRecorder from "vue-audio-recorder";
import WLoading from "./WLoading.vue";
export default {
  components: { WLoading },
  name: "WPickupConfirm",
  props: {
    value: {
      type: Object,
      default: null,
    },
    show: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    audio() {
      return this.value.audio;
    },
    isValid() {
      return this.url !== null && this.data && this.data.blob;
    },
    loader() {
      if (this.busy) return this.busy;
      return this.ids === null;
    },
    file_name() {
      let padded_number = "";
      if (this.index < 10) {
        padded_number = `00${this.index + 1}`;
      } else if (this.index < 100) {
        padded_number = `0${this.index + 1}`;
      } else {
        padded_number = `${this.index + 1}`;
      }

      return `utterance-${padded_number}.mp3`;
    },
  },
  data() {
    return {
      data: null,
      // audio: {},
      nextAudio: null,
      recording: false,
      url: null,
      busy: false,
      blob: null,
    };
  },
  mounted() {
    this.load();
  },
  watch: {
    value(data) {
      this.audio = data.audio;
      this.nextAudio = data.nextAudio;
      if (data.audio) this.url = data.audio.audio;
    },
  },
  methods: {
    ...mapActions("regalo-audio", ["patch"]),
    deleteAudio() {
      //   this.audio = null;
      this.url = null;
    },
    load() {
      this.audio = this.value.audio;
      this.nextAudio = this.value.nextAudio;
      if (this.value.audio) this.url = this.value.audio;
    },
    hideModal() {
      this.$emit("hide");
    },
    async save() {
      console.log("save");
      this.busy = "processing";
      await this.patch([
        this.audio._id,
        {
          audio: this.data.blob || this.blob,
        },
      ]);
      this.busy = false;
      this.$emit("nextOrClose");
      //   this.hideModal();
    },
    toggle_record() {
      this.recording = !this.recording;
    },

    callback(data) {
      if (data && data.url) {
        this.data = data;
        this.url = data.url;
      }
    },
  },
  filters: {},
};
</script>
<style>
.ar-records {
  display: none !important;
}
.ar-recorder__duration {
  margin: 0 !important;
}
.ar-player {
  display: none !important;
}
.record-preview,
.ar {
  width: 100% !important;
}
</style>
