<template>
  <div class="flex flex-col items-center justify-center py-8">
    <svg
      class="w-8 h-8 text-gray-500 animate-spin"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
    >
      <circle
        class="opacity-50"
        cx="12"
        cy="12"
        r="10"
        stroke="currentColor"
        stroke-width="4"
      ></circle>
      <path
        class="opacity-100"
        fill="currentColor"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
      ></path>
    </svg>
    <div class="mt-2 text-sm font-semibold text-gray-600">{{label || "Cargando"}}</div>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type:String,
      default: null
    },
  },
}
</script>

<style lang="scss" scoped></style>
